import { create } from "zustand"

export type UserOlefAttributes = {
    username: string
    providerName: string
    canSeeAll: boolean
}

export type UserOlefSetters = {
    setUserOlef: (payload: Partial<UserOlefAttributes>) => any
}

const initialState = localStorage.getItem('userOlef') ? JSON.parse(localStorage.getItem('userOlef')!) :{
    username: "",
    providerName: "",
    canSeeAll: false,
}

export const useUserOlefStore = create<UserOlefAttributes & UserOlefSetters | null>((set) => {
    return {
        ...initialState,
        setUserOlef: (payload: Partial<UserOlefAttributes>) => {
            set({...payload})
            localStorage.setItem('userOlef', JSON.stringify({ ...payload }))
        }
    }
})