import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Remove from "@shared/eurosport-ui/icons/Remove";
import { Add } from "@mui/icons-material";
import "../Accordion.scss";

const ReceptionContainer = (props: any) => {
  const [expanded, setExpanded] = useState<boolean>(checkHaveToBeExpanded());

  React.useEffect(() => {
    setExpanded(checkHaveToBeExpanded());
  }, [props.service]);

  function handleExpand() {
    setExpanded(!expanded);
  }

  function checkHaveToBeExpanded() {
    return (
      props.service &&
      [
        "frequency",
        "service",
        "modulation",
        "symbolRate",
        "fec",
        "polarisation",
      ].some((key) => props.service[key]?.length > 0)
    );
  }

  return (
    <Accordion
      expanded={expanded}
      className="accordion-header"
      onChange={() => {
        handleExpand();
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <Remove className="accordion-icon" />
          ) : (
            <Add className="accordion-icon" />
          )
        }
        sx={{
          borderRadius: expanded ? "5px 5px 0px 0px" : "5px",
        }}
      >
        RECEPTION
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Frequency</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Modulation</TableCell>
              <TableCell>Symbol Rate</TableCell>
              <TableCell>FEC</TableCell>
              <TableCell>Polarisation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.service && (
              <TableRow>
                <TableCell>{props.service.frequency}</TableCell>
                <TableCell>{props.service.service}</TableCell>
                <TableCell>{props.service.modulation}</TableCell>
                <TableCell>{props.service.symbolRate}</TableCell>
                <TableCell>{props.service.fec}</TableCell>
                <TableCell>{props.service.polarisation}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
export default ReceptionContainer;
