import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { IFacility } from "../../../models/Feeds";
import Add from "@shared/eurosport-ui/icons/Add";
import Remove from "@shared/eurosport-ui/icons/Remove";
import "../Accordion.scss";

const ServicesContainer = (props: any) => {
  const [expanded, setExpanded] = useState<boolean>(checkHaveToBeExpanded());

  React.useEffect(() => {
    setExpanded(checkHaveToBeExpanded());
  }, [props.service]);

  function handleExpand() {
    setExpanded(!expanded);
  }

  function checkHaveToBeExpanded() {
    return (
      props.service &&
      props.service.facilites &&
      props.service.facilites.length > 0
    );
  }

  return (
    <Accordion
      expanded={expanded}
      className="accordion-header"
      onChange={() => {
        handleExpand();
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <Remove className="accordion-icon" />
          ) : (
            <Add className="accordion-icon" />
          )
        }
        sx={{
          borderRadius: expanded ? "5px 5px 0px 0px" : "5px",
        }}
      >
        SERVICE
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Service Profile</TableCell>
              <TableCell>Facility</TableCell>
              <TableCell>IP Provider - Main</TableCell>
              <TableCell>IP Provider - Backup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.service &&
              props.service.facilites &&
              props.service.facilites.map(
                (facility: IFacility, index: number) => (
                  <TableRow
                    key={"Service_Facility_" + index}
                    sx={{
                      borderBottom: "1px solid #4F4F52",
                    }}
                  >
                    <TableCell>{props.service.service}</TableCell>
                    <TableCell>{props.service.profile}</TableCell>
                    <TableCell>{facility.trx_resource_desc}</TableCell>
                    <TableCell>{facility.main_ip_address}</TableCell>
                    <TableCell>{facility.backup_ip_address}</TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
export default ServicesContainer;
