import React, { useState } from "react";
import { DateRangePicker } from "@shared/eurosport-ui";
import dayjs from "dayjs";
import { InputAdornment, TextField } from "@mui/material";
import { Card, Grid } from "@mui/material";
import SearchIcon from "@shared/eurosport-ui/icons/Search";
import { Box } from "@mui/system";

const Menu = (props: any) => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [Search] = useState(props.Search);

  let timer: any = null;

  function updateFilterDate(start: string | null, end: string | null): void {
    const newStartDate: dayjs.Dayjs = dayjs(start);
    const newEndDate: dayjs.Dayjs = dayjs(end).add(1, "day");
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    updateFilters(newStartDate, newEndDate, Search);
  }

  function updateSearchField(e: any) {
    const value = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      const newStartDate: dayjs.Dayjs = dayjs(startDate.format("YYYY-MM-DD"));
      const newEndDate: dayjs.Dayjs = dayjs(endDate.format("YYYY-MM-DD")).add(
        1,
        "day"
      );
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      updateFilters(newStartDate, newEndDate, value);
    }, 500);
  }

  function updateFilters(start: dayjs.Dayjs, end: dayjs.Dayjs, search: string) {
    props.updateGrid(start, end, search);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        zIndex: "1100",
        width: "100%",
        margin: "auto",
      }}
    >
      <Grid item={true} xs={12}>
        <Card>
          <Grid
            container
            sx={{
              paddingLeft: "16px",
              background: "#2E2D38",
              paddingTop: "8px",
              paddingBottom: "8px",
              "& .MuiGrid-item": {
                marginRight: "10px",
              },
            }}
          >
            <Grid item>
              <TextField
                onChange={(evt: any) => updateSearchField(evt)}
                defaultValue={Search}
                placeholder="Search a feed..."
                style={{ width: "320px", height: "40px!important" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <DateRangePicker
                start={startDate.format()}
                end={endDate.format()}
                handleChange={updateFilterDate}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  );
};
export default Menu;
