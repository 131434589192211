import * as React from 'react'
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
    component: any
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props
    const isSignedIn:boolean = getCookie('user') !== ""
    const isAuthenticated: string = sessionStorage.getItem("isAuthenticated")!

    function getCookie(cname:string) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    
    return (
        <Route {...rest} render={(routeProps) => 
              isSignedIn && isAuthenticated === "true" ? (<Component {...routeProps} />) : 
              (<Redirect exact to={{ pathname: '/login', state: { from: routeProps.location } }} /> )
            }
        />
    )
}

export default PrivateRoute
