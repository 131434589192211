import React from "react";
import { Box } from "@mui/material";
import { MenuItem } from "@mui/material";

const ServicesListing = (props: any) => {
  function toggleService(index: number, e: any) {
    e.stopPropagation();
    props.setSelectedService(index);
    props.displayservice(props.services[index]);

    if (props.toggleDrawer) {
      props.toggleDrawer();
    }
  }

  return (
    <Box sx={{ backgroundColor: "#24232A", borderRadius: "8px" }}>
      <Box sx={{ padding: "16px", height: "calc(100% - 64px)" }}>
        {props.services &&
          Array.from(props.services).map((service: any, index: number) => (
            <MenuItem
              sx={{
                fontSize: "16px",
                fontWeight: 700,

                backgroundColor:
                  props.selectedService === index ? "#BCC2FF" : "",
                borderRadius: "4px",
                color: props.selectedService === index ? "#222327" : "",
              }}
              key={"Service_" + index}
              onClick={(e) => toggleService(index, e)}
            >
              {service.description}
            </MenuItem>
          ))}
      </Box>
    </Box>
  );
};
export default ServicesListing;
