import React, { useState } from "react";
import ServicesContainer from "./ServicesContainer/ServicesContainer";
import AudioContainer from "./AudioContainer/AudioContainer";
import ReceptionContainer from "./ReceptionContainer/ReceptionContainer";
import VideosContainer from "./VideosContainer/VideosContainer";
import "./OlefDetail.scss";
import ServicesListing from "./ServicesListing/ServicesListing";
import { IFeedDetail } from "../../models/Feeds";
import Close from "@shared/eurosport-ui/icons/Close";
import Arrow from "@shared/eurosport-ui/icons/Arrow";
import {
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { epgService } from "../Services/EpgService";
import { Box } from "@mui/system";

const OlefDetail = (props: any) => {
  const liveTypes = ["LIVE", "LIVE SHOW", "HLTS"];
  const rxTypes = ["RX", "L/RX", "VTR"];
  const replayTypes = ["REPLAY", "REPL"];

  const [selectedFeed, setSelectedFeed] = useState<IFeedDetail>();
  const [selectedService, setSelectedService] = useState(0);
  const [imageUrl, setImageUrl] = useState("");

  React.useEffect(() => {
    if (!props.open) {
      setSelectedFeed(undefined);
      setSelectedService(0);
    } else {
      if (props.services && props.services.length > 0) {
        displayservice(props.services[0]);
        epgService.getImage(props.feed.sportId).then((res) => {
          setImageUrl(res);
        });
      }
    }
  }, [props.open]);

  function displayservice(service: IFeedDetail) {
    setSelectedFeed(service);
  }

  function setService(index: number) {
    setSelectedService(index);
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} scroll="body">
      <Close
        onClick={props.handleClose}
        style={{ position: "absolute", top: 16, right: 24, cursor: "pointer" }}
      />
      <DialogTitle
        sx={{
          borderBottom: "1px solid #656568",
          fontSize: "18px",
          fontWeight: 700,
          color: "white",
          padding: "16px 24px",
          backgroundColor: "#2E2D38",
        }}
      >
        PROGRAM INFORMATIONS
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#2E2D38" }}>
        <Box sx={{ padding: "16px 0", display: "flex" }}>
          <Tooltip title="Previous feed">
            <Box
              className={`${!props.hasPrevious ? "arrows-disabled" : "arrows"}`}
              onClick={props.previousFeed}
            >
              <Arrow sx={{ transform: "scaleX(-1)" }}></Arrow>
            </Box>
          </Tooltip>
          <Box
            sx={{
              margin: "0 16px",
              backgroundColor: "#24232A",
              padding: "12px 16px",
              minWidth: "94%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <img src={imageUrl} className="image" alt="description"></img>
              </Box>

              <Box sx={{ marginLeft: "16px", flexGrow: 1 }}>
                <div className="title">{props.feed.name}</div>
                <div className="times">
                  Start time:{" "}
                  {moment(props.feed.lineup).format("DD/MM/YYYY HH:mm")}
                </div>
                <div className="times">
                  End time:{" "}
                  {moment(props.feed.endDate).format("DD/MM/YYYY HH:mm")}
                </div>
              </Box>

              <Box sx={{ marginTop: "4px" }}>
                <span
                  className={
                    props &&
                    props.feed &&
                    props.feed.broadcastType &&
                    liveTypes.includes(props.feed.broadcastType.toUpperCase())
                      ? "live"
                      : props &&
                        props.feed &&
                        props.feed.broadcastType &&
                        rxTypes.includes(props.feed.broadcastType.toUpperCase())
                      ? "rx"
                      : props &&
                        props.feed &&
                        props.feed.broadcastType &&
                        replayTypes.includes(
                          props.feed.broadcastType.toUpperCase()
                        )
                      ? "replay"
                      : "other"
                  }
                >
                  {props.feed.broadcastType}
                </span>
              </Box>
            </Box>
          </Box>
          <Tooltip title="Next feed">
            <Box
              className={`${!props.hasNext ? "arrows-disabled" : "arrows"}`}
              onClick={props.nextFeed}
            >
              <Arrow></Arrow>
            </Box>
          </Tooltip>
        </Box>

        <Grid container={true} spacing={1} className="gridContainerDetail">
          <Grid item={true} xs={12} lg={2}>
            <ServicesListing
              services={props.services}
              displayservice={displayservice}
              selectedService={selectedService}
              setSelectedService={setService}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            lg={10}
            sx={{ overflowY: "auto", maxHeight: "700px" }}
          >
            <ServicesContainer service={selectedFeed} />
            <VideosContainer service={selectedFeed} />
            <AudioContainer service={selectedFeed} />
            <ReceptionContainer service={selectedFeed} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OlefDetail;
