import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {RecoilRoot} from 'recoil'

ReactDOM.render(
    <RecoilRoot>
        <App/>
    </RecoilRoot>
    , document.getElementById('root') as HTMLElement
)
serviceWorker.unregister()
