import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from '../../login/login'
import OlefHeader from './OlefHeader'
import './Header.scss'
import Olefs from '../../Olefs/Olefs'
import PrivateRoute from '../../PrivateRoute'

class Header extends Component {
    render() {
        return (
            <BrowserRouter >
                <OlefHeader />
                <Switch>
                    <Route path="/login" component={Login} />
                    <PrivateRoute path="/" component={Olefs} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Header
