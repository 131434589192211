import React, { useState } from 'react'
import { useUserOlefStore } from '../../stores/user.store'
import { Avatar, Button, Typography } from '@mui/material'
import Down from "@shared/eurosport-ui/icons/Down"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import LogOut from "@shared/eurosport-ui/icons/LogOut"
import { useHistory } from "react-router-dom"

const Login = () => {
    const username: string | undefined = useUserOlefStore.getState()?.username
    const isAuthenticated: string = sessionStorage.getItem("isAuthenticated")!
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const history = useHistory()

    function logOut(){
        useUserOlefStore.setState(null)
        sessionStorage.setItem("isAuthenticated", "false")
        localStorage.setItem('userOlef', "")
        history.push('/login');
    }

    return (
        <>
            {(isAuthenticated === "true") &&
            <>
                <Button variant="text" 
                onClick={(e) => {
                    setMenuOpen((isOpen) => !isOpen)
                    setAnchorEl(e.currentTarget)
                  }}>
                    <Avatar sx={{ backgroundColor: "primary.main", color: "primary.contrastText" }}>{username![0]}</Avatar>
                    <Typography sx={{
                        color: 'white',
                        textTransform: 'none',
                        fontSize: '16px',
                        paddingRight: "8px"
                    }}>
                        {username}
                    </Typography>
                    <Down sx={{ color: "common.white", width: "12px", height: "12px" }} />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={() => setMenuOpen(false)}
                    MenuListProps={{
                    "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    sx={{
                    "& .MuiList-root": {
                        padding: 0,
                        minWidth: "170px",
                    },
                    }}
                >
                    <MenuItem onClick={logOut}>
                        <ListItemIcon sx={{ color: "text.primary" }}>
                            <LogOut />
                        </ListItemIcon>
                        <ListItemText sx={{ color: "text.primary" }}>Log Out</ListItemText>
                    </MenuItem>
                </Menu>
              </>
            }
        </>
    )
}

export default Login