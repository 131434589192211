import React, { useState } from 'react'
import {Dialog, DialogContent, DialogTitle} from '@mui/material'

import './AlertError.scss'

const AlertError = (props: any) => {
    const [open] = useState(props.open)
    const [handleClose] = useState(props.handleClose)
    const [message] = useState(props.message)

    return (
        <Dialog open={open} onClose={handleClose} scroll="body" >
            <DialogTitle className="error-title">
                ERROR
            </DialogTitle>
            <DialogContent className="error-content">
                {message ? message : 'An error occurs'}
                <p>Please contact support team <a href="mailto:DlDevSpot@discovery.com">DlDevSpot@discovery.com</a></p>
            </DialogContent>
        </Dialog>
    )
}

export default AlertError
