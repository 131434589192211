import React, { useState } from "react";
import { IAudio } from "../../../models/Feeds";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import "../Accordion.scss";

const AudioContainer = (props: any) => {
  const [expanded, setExpanded] = useState<boolean>(checkHaveToBeExpanded());

  React.useEffect(() => {
    setExpanded(checkHaveToBeExpanded());
  }, [props.service]);

  function handleExpand() {
    setExpanded(!expanded);
  }

  function checkHaveToBeExpanded() {
    return (
      props.service && props.service.audios && props.service.audios.length > 0
    );
  }

  return (
    <Accordion
      expanded={expanded}
      className="accordion-header"
      onChange={() => {
        handleExpand();
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <Remove className="accordion-icon" />
          ) : (
            <Add className="accordion-icon" />
          )
        }
        sx={{
          borderRadius: expanded ? "5px 5px 0px 0px" : "5px",
        }}
      >
        AUDIOS
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>T/C</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Routing</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>Destination</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.service &&
              props.service.audios &&
              props.service.audios.map((audio: IAudio) => (
                <TableRow key={audio.service_audio_no}>
                  <TableCell>{audio.tc}</TableCell>
                  <TableCell>{audio.track_language}</TableCell>
                  <TableCell>{audio.routing}</TableCell>
                  <TableCell>{audio.feedback}</TableCell>
                  <TableCell>{audio.destination}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
export default AudioContainer;
