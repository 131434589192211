import React, { useState, useEffect } from "react";
import { IFeedValue, IFeedDetail } from "../../models/Feeds";
import "./OlefGrid.scss";
import moment from "moment";
import {
  Grid,
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import OlefDetail from "../OlefDetail/OlefDetail";
import { olefService } from "../Services/OlefService";
import { IResponse } from "../../Common";

const initialFeed: IFeedValue = {
  name: "",
  wonosequence: "",
  description: "",
  lineup: "",
  startDate: "",
  endDate: "",
  broadcaster: "",
  origin: "",
  via: "",
  type: "",
  broadcastType: "",
  sportId: null,
};

const OlefGrid = (props: any) => {
  const liveTypes = ["LIVE", "LIVE SHOW", "HLTS"];
  const rxTypes = ["RX", "L/RX", "VTR"];
  const replayTypes = ["REPLAY", "REPL"];

  const [feeds, setFeeds] = useState(props.feeds);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [orderBy, setOrderBy] = React.useState(["", "asc"]);

  const [openFeedDetail, setOpenFeedDetail] = useState(props.openFeedDetail);

  const [feedSelected, setFeedSelected] = useState<IFeedValue>(initialFeed);
  const [services, setServices] = useState<IFeedDetail[]>([]);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(false);

  const showDetail = (feed: IFeedValue, index: number) => {
    setSelectedRow(index);
    openOlefDetail(feed);
    setHasPrevious(index !== 0);
    setHasNext(feeds.length > index + 1);
  };

  useEffect(() => {
    setFeeds(props.feeds);
    if (!props.openFeedDetail) {
      setSelectedRow(-1);
    }
  }, [props.feeds, props.openFeedDetail]);

  const sortBy = (columnName: string) => {
    if (feeds.length > 0) {
      if (orderBy[0] === columnName) {
        if (orderBy[1] === "asc") {
          sortDesc(columnName);
        } else {
          sortAsc(columnName);
        }
      } else {
        sortAsc(columnName);
      }
    }
  };

  const sortAsc = (columnName: string) => {
    let feedsOrdered = [];
    if (
      columnName === "name" ||
      columnName === "description" ||
      columnName === "broadcaster" ||
      columnName === "broadcastType"
    ) {
      feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
        if (!a[columnName]) {
          return 1;
        }
        if (!b[columnName]) {
          return -1;
        }
        return a[columnName].localeCompare(b[columnName]);
      });
    } else {
      feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
        if (!a.lineup) {
          return 1;
        }
        if (!b.lineup) {
          return -1;
        }
        return new Date(a.lineup).getTime() - new Date(b.lineup).getTime();
      });
    }
    setOrderBy([columnName, "asc"]);
    setFeeds(feedsOrdered);
  };

  const sortDesc = (columnName: string) => {
    let feedsOrdered = [];
    if (
      columnName === "name" ||
      columnName === "description" ||
      columnName === "broadcaster" ||
      columnName === "broadcastType"
    ) {
      feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
        if (!a[columnName]) {
          return -1;
        }
        if (!b[columnName]) {
          return 1;
        }
        return b[columnName].localeCompare(a[columnName]);
      });
    } else {
      feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
        if (!a.lineup) {
          return -1;
        }
        if (!b.lineup) {
          return 1;
        }
        return new Date(b.lineup).getTime() - new Date(a.lineup).getTime();
      });
    }
    setOrderBy([columnName, "desc"]);
    setFeeds(feedsOrdered);
  };

  function handleCloseFeedDetail() {
    setOpenFeedDetail(false);
    setSelectedRow(-1);
  }

  function openOlefDetail(feed: IFeedValue) {
    olefService
      .getFeedDetails(feed.wonosequence)
      .then((response: IResponse) => {
        if (response.callSuccess) {
          setServices(
            response.result.filter(
              (service: IFeedDetail) =>
                (service.facilites && service.facilites.length > 0) ||
                (service.audios && service.audios.length > 0)
            )
          );
          setFeedSelected(feed);
          setOpenFeedDetail(true);
        } else {
          setFeedSelected(initialFeed);
          setOpenFeedDetail(false);
          props.openError();
        }
      });
  }

  function previousFeed() {
    if (hasPrevious) {
      updateFeedDetail(selectedRow - 1);
    }
  }

  function nextFeed() {
    if (hasNext) {
      updateFeedDetail(selectedRow + 1);
    }
  }

  function updateFeedDetail(rowIndex: number) {
    const feedCurent = feeds[rowIndex];
    setOpenFeedDetail(false);
    openOlefDetail(feedCurent);
    setSelectedRow(rowIndex);
    setHasPrevious(rowIndex > 0);
    setHasNext(feeds.length > rowIndex + 1);
  }

  return (
    <>
      <Card className="grid">
        <Grid item={true} xs={12} md={12}>
          <Card>
            <div className="overflow-x">
              <Table
                className="-striped -highlight"
                stickyHeader
                sx={{ padding: "0 16px" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      textTransform: "uppercase",
                      "& th": {
                        fontSize: "12px",
                        fontWeight: 700,
                        color: "#BDBDBE",
                        backgroundColor: "#24232a !important",
                      },
                    }}
                  >
                    <TableCell
                      key={"7"}
                      onClick={() => sortBy("wonosequence")}
                      style={{ cursor: "pointer" }}
                    >
                      TO Number
                    </TableCell>
                    <TableCell
                      key="0"
                      onClick={() => sortBy("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Feed name
                      {orderBy[0] === "name" &&
                        (orderBy[1] === "asc" ? (
                          <ArrowDropUp style={{ verticalAlign: "bottom" }} />
                        ) : (
                          <ArrowDropDown style={{ verticalAlign: "bottom" }} />
                        ))}
                    </TableCell>
                    <TableCell
                      key="1"
                      onClick={() => sortBy("description")}
                      style={{ cursor: "pointer" }}
                    >
                      Description{" "}
                      {orderBy[0] === "description" &&
                        (orderBy[1] === "asc" ? (
                          <ArrowDropUp style={{ verticalAlign: "bottom" }} />
                        ) : (
                          <ArrowDropDown style={{ verticalAlign: "bottom" }} />
                        ))}
                    </TableCell>
                    <TableCell
                      key="2"
                      onClick={() => sortBy("lineup")}
                      style={{ cursor: "pointer" }}
                    >
                      Line Up (CET){" "}
                      {orderBy[0] === "lineup" &&
                        (orderBy[1] === "asc" ? (
                          <ArrowDropUp style={{ verticalAlign: "bottom" }} />
                        ) : (
                          <ArrowDropDown style={{ verticalAlign: "bottom" }} />
                        ))}
                    </TableCell>
                    <TableCell key="3">Start time (CET)</TableCell>
                    <TableCell key="4">End time (CET)</TableCell>
                    <TableCell
                      key="5"
                      onClick={() => sortBy("broadcaster")}
                      style={{ cursor: "pointer" }}
                    >
                      Broadcaster{" "}
                      {orderBy[0] === "broadcaster" &&
                        (orderBy[1] === "asc" ? (
                          <ArrowDropUp style={{ verticalAlign: "bottom" }} />
                        ) : (
                          <ArrowDropDown style={{ verticalAlign: "bottom" }} />
                        ))}
                    </TableCell>
                    <TableCell
                      key="6"
                      onClick={() => sortBy("broadcastType")}
                      style={{ cursor: "pointer" }}
                    >
                      Broadcast Type{" "}
                      {orderBy[0] === "broadcastType" &&
                        (orderBy[1] === "asc" ? (
                          <ArrowDropUp style={{ verticalAlign: "bottom" }} />
                        ) : (
                          <ArrowDropDown style={{ verticalAlign: "bottom" }} />
                        ))}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!feeds.length ? (
                    feeds.map((feed: IFeedValue, rowIndex: number) => (
                      <TableRow
                        key={`${feed.wonosequence}-${rowIndex}`}
                        className="table-striped cursor-pointer"
                        selected={selectedRow === rowIndex}
                        onClick={() => showDetail(feed, rowIndex)}
                      >
                        <TableCell
                          style={{ borderBottom: "1px solid #4F4F52" }}
                        >
                          {feed.wonosequence}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "300px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            borderBottom: "1px solid #4F4F52",
                          }}
                          key={rowIndex + "_0"}
                        >
                          <span title={feed.name}>
                            <b className={"feedName"}>{feed.name}</b>
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "300px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            borderBottom: "1px solid #4F4F52",
                          }}
                        >
                          <Tooltip title={feed.description || ""}>
                            <span>{feed.description}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          key={rowIndex + "_1"}
                          style={{ borderBottom: "1px solid #4F4F52" }}
                        >
                          {moment(feed.lineup).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell
                          key={rowIndex + "_2"}
                          style={{ borderBottom: "1px solid #4F4F52" }}
                        >
                          {feed.startDate
                            ? moment(feed.startDate).format("HH:mm")
                            : "No start date"}
                        </TableCell>
                        <TableCell
                          key={rowIndex + "_3"}
                          style={{ borderBottom: "1px solid #4F4F52" }}
                        >
                          {moment(feed.endDate).format("HH:mm")}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "250px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            borderBottom: "1px solid #4F4F52",
                          }}
                          key={rowIndex + "_4"}
                        >
                          <span title={feed.broadcaster}>
                            {feed.broadcaster}
                          </span>
                        </TableCell>
                        <TableCell
                          key={rowIndex + "_5"}
                          style={{ borderBottom: "1px solid #4F4F52" }}
                        >
                          <span
                            className={
                              liveTypes.includes(
                                feed.broadcastType.toUpperCase()
                              )
                                ? "live"
                                : rxTypes.includes(
                                    feed.broadcastType.toUpperCase()
                                  )
                                ? "rx"
                                : replayTypes.includes(
                                    feed.broadcastType.toUpperCase()
                                  )
                                ? "replay"
                                : "other"
                            }
                          >
                            {feed.broadcastType}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={99}>No Feeds</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>
      </Card>

      <OlefDetail
        services={services}
        open={openFeedDetail}
        handleClose={handleCloseFeedDetail}
        feed={feedSelected}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        previousFeed={previousFeed}
        nextFeed={nextFeed}
      />
    </>
  );
};
export default OlefGrid;
